import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67')
];

export const server_loads = [0,3];

export const dictionary = {
		"/(doc)": [57,[6]],
		"/(app)/account": [13,[2,3]],
		"/(app)/account/api-keys": [14,[2,3]],
		"/(app)/account/apps": [15,[2,3]],
		"/(app)/account/apps/authorize": [~16,[2,3]],
		"/(app)/account/change-email": [~17,[2,3]],
		"/(app)/account/subscription/downgrade": [~18,[2,3]],
		"/(app)/account/subscription/downgrade/failed": [19,[2,3]],
		"/(app)/account/subscription/downgrade/succeeded": [~20,[2,3]],
		"/(app)/account/usage": [21,[2,3]],
		"/(app)/admin": [22,[2,4]],
		"/(app)/admin/gallery": [23,[2,4]],
		"/(app)/admin/logs": [~24,[2,4]],
		"/(app)/admin/system": [25,[2,4]],
		"/(app)/admin/users": [26,[2,4,5]],
		"/(app)/auth/error/code": [27,[2]],
		"/(app)/auth/error/oauth": [28,[2]],
		"/(doc)/blog": [58,[6,7]],
		"/(doc)/blog/[slug]": [59,[6,7]],
		"/(app)/connect/discord": [~29,[2]],
		"/(app)/contact": [30,[2]],
		"/(app)/discord": [31,[2]],
		"/(doc)/docs": [60,[6,8]],
		"/(doc)/docs/v1": [61,[6,8]],
		"/(doc)/docs/v1/[...slug]": [62,[6,8]],
		"/(app)/featured-on": [32,[2]],
		"/(app)/gallery": [33,[2]],
		"/(app)/gallery/o/[output_id]": [34,[2]],
		"/(app)/generate": [35,[2]],
		"/(app)/generate/m/[model_slug]": [~36,[2]],
		"/(app)/github": [37,[2]],
		"/(doc)/guide": [63,[6,9]],
		"/(doc)/guide/[...slug]": [64,[6,9]],
		"/(app)/history": [38,[2]],
		"/(app)/instagram": [39,[2]],
		"/(app)/legal": [40,[2]],
		"/(app)/live": [41,[2]],
		"/(app)/pricing": [~42,[2]],
		"/(app)/pricing/purchase/cancelled": [43,[2]],
		"/(app)/pricing/purchase/succeeded": [44,[2]],
		"/(app)/privacy": [45,[2]],
		"/(app)/producthunt": [46,[2]],
		"/(app)/sign-in": [47,[2]],
		"/(app)/sign-up": [~48,[2]],
		"/(app)/support": [49,[2]],
		"/(app)/terms": [50,[2]],
		"/(test)/testasdf": [67],
		"/(doc)/try/models": [65,[6,10]],
		"/(doc)/try/models/[...slug]": [66,[6,10]],
		"/(app)/twitter": [51,[2]],
		"/(app)/user/[username]": [52,[2]],
		"/(app)/user/[username]/o/[output_id]": [53,[2]],
		"/(app)/voiceover": [54,[2]],
		"/(app)/voiceover/generate": [~55,[2]],
		"/(app)/x": [56,[2]],
		"/(app)/[username]": [11,[2]],
		"/(app)/[username]/o/[output_id]": [12,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';